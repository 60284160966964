import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import Logo from './components/Logo';
class Contact extends Component{
	componentDidMount() {
		console.log('contact componentDidMount');
		if (typeof window.ReloadPage === 'function') { console.log('fire ReloadPage'); window.ReloadPage();}
	}
	render(){
		return(
			<React.Fragment>
				<style>
				{`
				.public-pages {
					min-height: calc(100vh - 50px);
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					padding-top: 10px;
					padding-bottom: 100px;
					position: relative;
					}
					.overlay {
					background-color: rgba(0, 0, 0, 0.85);
					}
					.jumbotron {
					background: none;
					color: #ffffff;
					padding: 2rem;
					}
					.about-title {
					font-size: 4rem;
					font-weight: 600;
					}
					.about-left-title {
					font-family: 'Caveat', cursive;
					border-bottom: 1px dashed #ffffff;
					padding-bottom: 1rem;
					}
				`}
				</style>
				<Container>
					<Row>
						<Col lg={12}>
							<div className="text-center mt-3 mb-3">
								<Logo />
								<p className="lead-desc">
									<a href="https://contrib.com/">Proud Member of CONTRIB</a> | 
									<a href="https://vnoc.com">Powered by VNOC</a>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="public-pages" style={{ backgroundImage: `url(${window.Configs.background_image})` }}>
				<div className="overlay"></div>
					<Container>
						<Row>
							<Col lg={12}>								
								<Row>
									<Col lg={{span: 10, offset: 1}} className="text-left">
										<Jumbotron>
											<Row className="mb-0">
												<Col>
													<h2 className="about-title text-center mb-3">
														Contact Us
													</h2>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<div className="contact-script"></div>
												</Col>
											</Row>											
										</Jumbotron>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Contact;