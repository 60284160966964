import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
//import styled from 'styled-components';

//const Styles = styled.div`
	//Change internal CSS
	//.navbar {
	//	background-color: red;
	//}

	//.navbar-brand, .navbar-brand .nav-link {
	//	color: #fff;

	//	&:hover{
	//		color: #000;
	//	}
	//}
//`;

class NavigationBar extends Component{
	render(){
		return (
			//<Styled>
			<React.Fragment>
				<div id={'tickerscript'}>
        		</div>
        		<div id={'ticker'}>
        		</div>
			<style>
			{`
			.navL {
				color: rgba(255,255,255,.5);
			}
			`}
			</style>
				<Navbar bg="primary" variant="dark" expand="lg">
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="basic-navbar-nav">
				    <Nav className="mr-auto ml-lg-auto">
				      <Nav.Link as={Link} to="/">
				      	Home
				      </Nav.Link>
				      <a target="_blank" rel="noopener noreferrer" href={'https://www.contrib.com/to/'+window.Configs.domain} className="nav-link">
						Contribute
					</a>
					<Nav.Link as={Link} to="/partner">
				      	Partner
				      </Nav.Link>
					<a target="_blank" rel="noopener noreferrer" href={'https://domaindirectory.com/contact?domain='+window.Configs.domain} className="nav-link">
						Inquire
					</a>
					<Nav.Link as={Link} to="/about">
				      	About
				      </Nav.Link>
					 <Nav.Link as={Link} to="/contact">
				      	Contact Us
				      </Nav.Link>
				    </Nav>
				  </Navbar.Collapse>
				</Navbar>
			</React.Fragment>
			//</Styled>
		);
	}
}

export default NavigationBar;
