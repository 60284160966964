import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
class Invest extends Component{
	componentDidMount() {
		console.log('invest componentDidMount');
		if (typeof window.ReloadPage === 'function') { console.log('fire ReloadPage'); window.ReloadPage();}
	}
	render(){
		return(
			<React.Fragment>
				<style>
				{`
				.public-pages {
					min-height: calc(100vh - 50px);
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					padding-top: 20px;
					padding-bottom: 100px;
					position: relative;
					}
					.overlay {
					background-color: rgba(0, 0, 0, 0.85);
					}
					.jumbotron {
					background: none;
					color: #ffffff;
					padding: 2rem 5rem;
					}
					.about-title {
					font-size: 3rem;
					font-weight: 600;
					}
					.about-left-title {
					font-family: 'Caveat', cursive;
					border-bottom: 1px dashed #ffffff;
					padding-bottom: 1rem;
					}
					.nvtabs .nav-tabs {
						width: 100%;						
					}
					.nvtabs .nav-tabs .nav-link {
						font-weight: 600;
						font-size: 1.25rem;
						background-color: #110F0F;
						border-color: #555 #555 #555;
						border-bottom: none;
					}
					.tab-content {
						background: rgba(0,0,0,.5);
						padding: 30px;
						border: 1px solid #555;
						border-top: none;
						border-radius: 0px 0px 6px 6px;
					}
					.ir-amount {
						font-size: 1.55rem;
						font-weight: 600;
						display: block;
					}
				`}
				</style>				
				<div className="public-pages" style={{ backgroundImage: `url(${window.Configs.background_image})` }}>
				<div className="overlay"></div>					
					<Container>
						<Row>
							<Col lg={12}>
								<Row>
										<Col md={12}>
											<div className="invest-script"></div>
										</Col>
								</Row>	
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Invest;