import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Apps from './Apps';
import Privacy from './Privacy';
import Terms from './Terms';
import Cookie from './Cookie';
import Invest from './Invest';
import Contact from './Contact';
import NoMatch from './NoMatch';
import Buy from './Buy';
import Buy2 from './Buy2';
import Partner from './Partner';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';

class App extends Component {
	render() {
	  
    return (
	  <React.Fragment>
		<Helmet>
			<link rel="canonical" href={"https://"+window.Configs.domain} />
			<title>{window.Configs.title}</title>
			<meta name="title" content={window.Configs.title} />
			<meta name="description" content={window.Configs.domain+' - '+window.Configs.description} />
			<meta name="keywords" content={window.Configs.keywords} />
			<meta name="author" content={window.Configs.domain} />
			{ window.Configs.adsense_client_id!==''?(<script async='' src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${window.Configs.adsense_client_id}`} crossorigin="anonymous" data-react-helmet="true" data-checked-head="true"></script>):'' }
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${window.Configs.account_ga}`}></script>
			<script>
				{`
					 window.dataLayer = window.dataLayer || [];
					 function gtag(){dataLayer.push(arguments);}
					 gtag('js', new Date());
				 
					 gtag('config', '${window.Configs.account_ga}');
				`}
			</script>
			<script type="text/javascript">
				{`
					var _paq = window._paq || [];
					_paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
					_paq.push(["setCookieDomain", "*.${window.Configs.domain}"]);
					_paq.push(["setDomains", ["*.${window.Configs.domain}"]]);
					_paq.push(['trackPageView']);
					_paq.push(['enableLinkTracking']);
					(function() {
						var u="//www.stats.numberchallenge.com/";
						_paq.push(['setTrackerUrl', u+'matomo.php']);
						_paq.push(['setSiteId', ${window.Configs.piwik_id}]);
						var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
						g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
					})();
				`}
			</script>
			<noscript>{`<p><img src="${"//www.stats.numberchallenge.com/matomo.php?idsite="+window.Configs.piwik_id}" alt="" /></p>`}</noscript>
			<script src="https://tools.contrib.com/js/glue.js"></script>
			<script src="https://tools.contrib.com/js/tickerglue.js"></script>
		</Helmet>
        <Router>
          <NavigationBar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/apps" component={Apps} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route path="/cookie" component={Cookie} />
		  <Route path="/invest" component={Invest} />
		  <Route path="/contact" component={Contact} />
              <Route path="/buy" component={Buy} />
              <Route path="/buy2" component={Buy2} />
              <Route path="/partner" component={Partner} />
              <Route component={NoMatch} />
            </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;