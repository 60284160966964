import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import Logo from './Logo';

export const PublicLayout = (props) => (
	<React.Fragment>
		<div className="public-pages">
			<Container>
				<Row>
					<Col lg={12}>
						<div className="text-center mb-5">
							<Logo />
							<p className="lead-desc">
								<a href="https://contrib.com/">Proud Member of CONTRIB</a> | <a href="https://vnoc.com">Powered by VNOC</a>
							</p>
						</div>
						<Row>
							<Col lg={{span: 10, offset: 1}} className="text-center">
								<Jumbotron>
									{props.children }
								</Jumbotron>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	</React.Fragment>
)